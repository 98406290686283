import { api } from "../../../utils/axios-utils"
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const modifiedEndDate=(dateString)=>{
    const dateObj = new Date(dateString);
    dateObj.setDate(dateObj.getDate() - 1);
    const adjustedYear = dateObj.getFullYear();
    const adjustedMonth = String(dateObj.getMonth() + 1).padStart(2, '0');
    const adjustedDay = String(dateObj.getDate()).padStart(2, '0');
    return `${adjustedYear}-${adjustedMonth}-${adjustedDay}`;
}

export function RetrieveShopBusyAppointment(shopUid,setDataListing){

    api.get(`shop/${shopUid}/shop-busy-details/`)
   .then((res)=>{
    setDataListing(res?.data)
        //    console.log(res?.data)
   }) 
   .catch((err)=>{
       console.log(err)
   })
}

export function FetchUserInfo(token,setShopUid){

    api.get(`auth/user-info/`)

    .then((res)=>{
         setShopUid(res?.data?.shop_details[0]?.uid)
        // console.log(res)
    })
    .catch((err)=>{
        toast(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        console.log(err)
    })
}

export function CreateShopBusyAppointment(shopUid,startTime,endTime,setErrorMessage,setDataListing,title,startDate,endDate){

  

    function getDateFromString(dateString) {
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        const newDate=`${year}-${month}-${day}`
        return newDate;
    }
    
    function getTime(datetimeString) {
        let datetimeObj = new Date(datetimeString);
        let time = datetimeObj.toTimeString().split(' ')[0];
        return  time
    }


    const start =  getTime(startTime)
    const end =getTime(endTime)


    // const [startDate,startday]=getDateFromString(startTime)
    // const [endDate,endday]=getDateFromString(endTime)
    // const endd=modifiedEndDate(endDate)

    let busySlot
    // console.log(endDate)
        
        if(start===end){
            busySlot=[{
                "start_date":getDateFromString(startDate),
                "end_date":getDateFromString(endDate),
                "is_day_busy" :true 
            }]
        }
        else{
          busySlot = [ {
            "start_date":getDateFromString(startDate),
            "end_date":getDateFromString(endDate),
                "is_day_busy": false,
                "busy_slots":
                   [{
                        "busy_from": start,
                        "busy_to": end
                    }],
            }]
        }

        let data ;

        if(startDate<endDate && start!=end){
            busySlot = [ {
                "start_date":getDateFromString(startDate),
                "end_date":getDateFromString(endDate),
                    "is_day_busy": false,
                    "busy_slots":
                       [{
                            "busy_from": start,
                            "busy_to": end
                        }],
                }]
        }

        if(shopUid){
          data ={
             "shop_uid": shopUid,
             "slots":busySlot,
             "title":title,
            }
        } 

    api.post(`shop/busy-setting/bulk-create/`, 
           data
        )

        .then((res)=>{
                console.log(res)
                RetrieveShopBusyAppointment(shopUid,setDataListing)

        }) 
        .catch((err)=>{
            console.log(err)
            setErrorMessage((err?.response?.data?.message)? (err?.response?.data?.message) : (err?.response?.data?.error) )
        })
}

export function UpdateShopBusyAppointment(props,title,startDate,endDate){
    const { shopUid,startTime,endTime,shop_busy_setting_uid,uid,setDataListing}=props

    function getDateFromString(dateString) {
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        const newDate=`${year}-${month}-${day}`
        return newDate;
    }

    function getDayFromString(dateString) {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const newDate=`${day}`
        return newDate;
    }
    
    function getTime(datetimeString) {
        let datetimeObj = new Date(datetimeString);
        let time = datetimeObj.toTimeString().split(' ')[0];
        return  time
    }
    
    const modifiedStartDate=getDayFromString(startDate)
    const modifiedEndDate=getDayFromString(endDate)

    const start =  getTime(startTime)
    const end =getTime(endTime)

    // console.log(startTime,endTime)

  

    let busySlot
        if(uid && start!=end && modifiedStartDate==modifiedEndDate){

            busySlot =  {
                shop_busy_setting_uid:shop_busy_setting_uid,
                is_day_busy: false,
                shop_busy_timing_slot_uid:uid,
                busy_from: start,
                busy_to: end,
                // start_date:getDateFromString(startDate),
                // end_date:getDateFromString(endDate),
                title:title
            }
            
        }
 
        else if(!uid && start!=end && modifiedStartDate==modifiedEndDate){
            busySlot =  {
                shop_busy_setting_uid:shop_busy_setting_uid,
                is_day_busy: false,
                // start_date:getDateFromString(startDate),
                // end_date:getDateFromString(endDate),
                busy_from: start,
                busy_to: end,
                title:title
            }
        }
        else if(modifiedStartDate<modifiedEndDate && start!=end){
            busySlot =  {
                shop_busy_setting_uid:shop_busy_setting_uid,
                shop_busy_timing_slot_uid:uid,
                is_day_busy: false,
                start_date:getDateFromString(startDate),
                end_date:getDateFromString(endDate),
                busy_from: start,
                busy_to: end,
                title:title
            }
        }
        else {
            busySlot={
                shop_busy_setting_uid:shop_busy_setting_uid,
                is_day_busy :true,
                title:title,
                start_date:getDateFromString(startDate),
                end_date:getDateFromString(endDate),
            }
        }

        
    api.patch(`shop/busy-setting/update/`, 
           busySlot
        )

        .then((res)=>{
                console.log(res)
          RetrieveShopBusyAppointment(shopUid,setDataListing)
        
        }) 
        .catch((err)=>{
            console.log(err)
            // setErrorMessage(err?.response?.data?.message)
        })
}


export function DeleteParticularShopBusy(deleteProps){
    const {shopUid,shopBusyTimingSlotUid,startDate,setDataListing}=deleteProps
    const formData=new FormData()
    formData.append("timing_slot_uid", shopBusyTimingSlotUid);
    console.log({shopBusyTimingSlotUid})
    if(shopBusyTimingSlotUid){
        api.delete(`shop/${shopUid}/busy-setting/delete/`,{
            "data": formData
         })
     
        .then((res)=>{
                console.log(res)
                RetrieveShopBusyAppointment(shopUid,setDataListing)
        }) 
        .catch((err)=>{
            console.log(err)
        })
    }else{
        api.delete(`shop/${shopUid}/busy-setting/delete/?start_date=${startDate}&end_date=${startDate}`)
        .then((res)=>{
                console.log(res)
                RetrieveShopBusyAppointment(shopUid,setDataListing)

        }) 
        .catch((err)=>{
            console.log(err)
        })
    }
    
}

export function AcceptAppointment(uid){
     api.patch(`appointment/${uid}/`,
        {
          status: 'accepted',
        },
      )
    .then((res)=>{
        //  setShopUid(res?.data?.shop_details[0]?.uid)
        console.log(res)
    })
    .catch((err)=>{
  
        console.log(err)
    })
}

export function AcceptWorkOrder(work_order_uid){

    api.patch(`appointment/work-order/update/${work_order_uid}/`,{
        status: "accepted",
    })

    .then((res)=>{
        console.log(res)
    })
    .catch((err)=>{
        toast(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        console.log(err)
    })
}

export function CancelAppointment(uid,reason){

     api.patch(`appointment/${uid}/`,
        {
          is_cancelled: "True",
          reason_for_cancellation: reason,
        },
      )
    .then((res)=>{
        console.log(res)
    })
    .catch((err)=>{
        console.log(err)
    })
}

export function cancelWorkOrder(work_order_uid){

    api.patch(`appointment/work-order/update/${work_order_uid}/`,{
        status: "cancelled",
        is_cancelled: 'True',
        reason_for_cancellation: ""
    })

    .then((res)=>{
        //  setShopUid(res?.data?.shop_details[0]?.uid)
        console.log(res)
    })
    .catch((err)=>{
        toast(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        console.log(err)
    })
}

export const rescheduleAppointmentApi = async (uid,workinghouruid,date,startTime,endTime,setStartTime,setEndTime,setPendingOn) => {
    function getTime(datetimeString) {
        let datetimeObj = new Date(datetimeString);
        let time = datetimeObj.toTimeString().split(' ')[0];
        return  time
    }

    startTime=getTime(startTime)
    endTime=getTime(endTime)


    function createTimestampStrings(date, startTime,endTime) {
        const startTimestamp = `${date}T${startTime}`;
        const endTimestamp = `${date}T${endTime}`;
         setStartTime(startTimestamp)
         setEndTime(endTimestamp) 
    }


   api.post(
        `appointment/reschedule-request/`,
        {
            "appointment": uid,
            "working_hour": workinghouruid,
            "proposed_date":date,
            // "proposed_end_date":date,
            "start_time": startTime,
            "end_time": endTime,
        })
      .then((res)=>{
        console.log(res)
        setPendingOn(res?.data?.pending_on)
      })
      .catch((err)=>{
        console.log(err)
      })
  };

 export const rescheduleWorkorderApi =  (uid,date,startTime,endTime,setPendingOn) => {

    function getTime(datetimeString) {
        let datetimeObj = new Date(datetimeString);
        let time = datetimeObj.toTimeString().split(' ')[0];
        return  time
    }

    startTime=getTime(startTime)
    endTime=getTime(endTime)

    // console.log(startTime,endTime)

   api.post(
        `appointment/work-order/reschedule-request/`,
       { 
        work_order:uid,
        proposed_date: date,
        // proposed_end_date: date,
        start_time: startTime,
        end_time: endTime,
    })
      .then((res)=>{
        console.log(res)
        setPendingOn(res?.data?.pending_on)
      })
      .catch((err)=>{

      })
   
  };

 export function getWorkorderListDetails(uid,setAppointmentDetail,setStartDate,setEndTime,setStartTime,setStatus,setPendingOn){
// Function to extract start time and end time from the time slot string
function extractTimes(timeSlot) {
    const times = timeSlot.split(' - ');
    const startTime = times[0];
    const endTime = times[1];
    return { startTime, endTime };
}

// Function to create a valid timestamp string
function createTimestampStrings(date, timeSlot) {
    const { startTime, endTime } = extractTimes(timeSlot);
    const startTimestamp = `${date}T${startTime}`;
    const endTimestamp = `${date}T${endTime}`;
     setStartTime(startTimestamp)
     setEndTime(endTimestamp) 
}

     api.get(`appointment/work-order/detail/${uid}/`)
     .then((res)=>{
        // console.log(res)
        setAppointmentDetail(res?.data)
        setStatus(res?.data?.status)
        setStartDate(res?.data?.date)
        setPendingOn(res?.data?.pending_on)
        createTimestampStrings(res?.data?.date,res?.data?.time_slot)
                // setStatus(res?data?.start_date)
     })
     .catch(err=>console.log(err))
  }

  export function getAppointmentListDetails(uid,setAppointmentDetail,setStartDate,setEndTime,setStartTime,setStatus,setPendingOn){
    function extractTimes(timeSlot) {
        const times = timeSlot.split(' - ');
        const startTime = times[0];
        const endTime = times[1];
        return { startTime, endTime };
    }
    
    // Function to create a valid timestamp string
    function createTimestampStrings(date, timeSlot) {
        const { startTime, endTime } = extractTimes(timeSlot);
        const startTimestamp = `${date}T${startTime}`;
        const endTimestamp = `${date}T${endTime}`;
         setStartTime(startTimestamp)
         setEndTime(endTimestamp) 
    }

    api.get(`appointment/${uid}/`)
     .then((res)=>{
        console.log(res)
        setAppointmentDetail(res?.data)
        setStartDate(res?.data?.date)
        setStatus(res?.data?.status)
        setPendingOn(res?.data?.pending_on)
        createTimestampStrings(res?.data?.date,res?.data?.time_slot)
        // console.log(res?.data?.date)
     })
     .catch(err=>{
        console.log(err)
    return})
  }
  