
const appId = process.env.REACT_APP_MICROSOFT_APP_ID;
const redirectUri = process.env.REACT_APP_REDIRECT_URI;


export const microsoftConfig={
    appId:appId,
    redirectUri: redirectUri,
    scopes:['user.read','profile'],
    // authority:"https://login.microsoftonline.com/BuckHollowInvestments.com/"
}