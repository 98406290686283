// import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect } from 'react';
import './App.css';
// import dashboard from "./assets/desktop.jpg"
// import MyAppointments from './pages/myAppointment';
import Login from "./pages/login";
import {gapi} from "gapi-script"
import {BrowserRouter ,Routes,Route} from "react-router-dom"
import MyAppointments from './pages/Appointments/SchedulePage';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://510ba49d34329c0bf2572df3a3e53e20@o4507762938937344.ingest.us.sentry.io/4507763699023872",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "http://b4youfly-xh.sentry.io/api/"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const clientId =process.env.REACT_APP_GOOGLE_CLIENT_ID
  const access_token = localStorage.getItem("accessToken")

  useEffect(()=>{
    function start() {
      gapi.client.init({
        clientId:clientId,
        scope:"email"
      })
    };
    gapi.load('client:auth2',start)
  },[])



  return (
    <div >
        <BrowserRouter>
          <Routes>
            {access_token == null ?(
              <Route path='' element={<Login/>}/>
            ):(
              <Route path='' element={<MyAppointments/>}/>
            )}
         
           
            {/* <Route path='/appointments' element={<MyAppointments/>}/> */}
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;

