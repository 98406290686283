import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


export default function TimeRange({Time,setTime}) {

  return (
    <div> 
  <LocalizationProvider dateAdapter={AdapterDayjs}>
  <DemoContainer  components={['TimePicker']}>
    <div className='timepicker'>
    <TimePicker  onAccept={(val)=> {
        setTime(val?.$d)}}
        defaultValue={dayjs(Time)}
    />
    </div>
  </DemoContainer>
</LocalizationProvider>
</div>
    
  );
}