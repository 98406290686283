import logo from "../assets/logo.png";
import { useState, useEffect } from "react"
import { GrGooglePlus } from "react-icons/gr";
import { BsMicrosoft } from "react-icons/bs";
// import { useGoogleOneTapLogin,GoogleLogin  } from '@react-oauth/google';
import { GoogleLogin } from "react-google-login";
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import "./loginPage.css"
// import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom"
import { PublicClientApplication } from '@azure/msal-browser'
import { microsoftConfig } from "../Configs/config";
import { AppleLoginApi, FacebookLoginApi, GoogleLoginApi, MicrosoftLoginApi } from "../axiosApi/authApi";
// import { useAuth } from "../authContext/authContext";
import { ToastContainer, toast } from 'react-toastify';
import AppleLogin from 'react-apple-login'
import axios from "axios";





const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID
const appleClientId = process.env.REACT_APP_APPLE_CLIENT_ID
const RedirectUri=process.env.REACT_APP_REDIRECT_URI
// const loginRequest = {
//     scopes: ["User.Read"]
// };


console.log(appleClientId,RedirectUri)

const essential = {
  error: null,
  isAuthenticated: false,
  user: {}
}

function Login() {
  const [googleToken, setGoogleToken] = useState(null)
  const [loginDomain, setLoginDomain] = useState(null)
  const [facebookToken, setFacebookToken] = useState(null)
  const [microsoftToken, setMicrosoftToken] = useState(null)
  const [appleToken, setAppleToken] = useState(null)
  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false)
  const [isFacebookSignIn, setIsFacebookSignIn] = useState(false)
  const [isMicrosoftSignIn, setIsMicrosoftSignIn] = useState(false)
  const [isAppleSignIn, setIsAppleSignIn] = useState(false)
  const [appleCode, setAppleCode] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState()
  const [TermsAndCondition, setTermsAndCondition] = useState(false)

  const [microsoftEssen, setMicrosoftEssen] = useState(essential)
  const [isSdkLoaded, setIsSdkLoaded] = useState(true);



  useEffect(() => {
    const checkAppleIdSdk = () => {
      if (window.AppleID) {
        setIsSdkLoaded(true);
      }
    };
   
    // Check if AppleID SDK is loaded
    checkAppleIdSdk();

    // Add an event listener to check again if the SDK is not yet loaded
    if (!isSdkLoaded) {
      const intervalId = setInterval(() => {
        checkAppleIdSdk();
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isSdkLoaded]);

  const navigate = useNavigate()

  // useEffect(() => {
  //   if (googleToken) {
  //     //  setTimeout(() => {
  //     GoogleLoginApi("shop-owner", googleToken, setIsGoogleSignIn, setErrorMessage)
  //     //  }, 2000); 
  //   } else if (facebookToken && loginDomain === "facebook") {
  //     FacebookLoginApi("shop-owner", facebookToken, setIsFacebookSignIn, setErrorMessage)
  //   }
  //   else if (microsoftToken) {
  //     MicrosoftLoginApi("shop-owner", microsoftToken, setIsMicrosoftSignIn, setErrorMessage)
  //   }
  //   else if (appleToken) {
  //     AppleLoginApi("shop-owner", appleToken, setIsAppleSignIn, setErrorMessage, appleCode)
  //   }
  // }, [googleToken, facebookToken, microsoftToken, appleToken])

  useEffect(() => {
    if (isGoogleSignIn) {
      //    PrivateLogin()
      window.location.reload()
      //  navigate("/appointments")
    }
    else if (isFacebookSignIn) {
      //    PrivateLogin()
      window.location.reload()
      // navigate("/appointments")
    }
    else if (isMicrosoftSignIn) {
      //    PrivateLogin()
      window.location.reload()
      // navigate("/appointments")
    }
    else if (isAppleSignIn) {
      window.location.reload()
      //  navigate("/appointments")
    }
    setIsLoading(false)
  }, [isGoogleSignIn, isFacebookSignIn, isMicrosoftSignIn, isAppleSignIn])

  const onSuccess = (res) => {
   const token = res?.tokenId
    setGoogleToken(token)
    setIsLoading(true)
    localStorage.setItem("loginWith", res?.tokenObj?.idpId)
    GoogleLoginApi("shop-owner", token, setIsGoogleSignIn, setErrorMessage)

  }

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage)
    }
    setErrorMessage(null)
  }, [errorMessage])

  const clickedComponent = () => {
    console.log("data")
  }

  const onFailure = (err) => {
    console.log("login error", err)
  }

  const responseFacebook = (response) => {
    const token = response?.accessToken
    setLoginDomain(response?.graphDomain)
    setFacebookToken(token)
    setIsLoading(true)
    FacebookLoginApi("shop-owner", token, setIsFacebookSignIn, setErrorMessage)

  }

  const handleMicrosoftLoginClick = async () => {
    if (TermsAndCondition) {
      try {

        const publicClientApplication = new PublicClientApplication({
          auth: {
            clientId: microsoftConfig.appId,
            redirectUri: microsoftConfig.redirectUri,
            // authority: microsoftConfig.authority
          },
          cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: true
          }
        });

        // Initialize MSAL PublicClientApplication instance
        await publicClientApplication.initialize();

        const loginResponse = await publicClientApplication.loginPopup({
          scopes: microsoftConfig.scopes,
          prompt: "select_account"
        });

        if (loginResponse && loginResponse.account) {
          setIsLoading(true)
          console.log({ loginResponse })
          const token = loginResponse?.accessToken
          let tempMicroEssen = { ...microsoftEssen };
          tempMicroEssen.isAuthenticated = true;
          setMicrosoftEssen(tempMicroEssen);
          setMicrosoftToken(token)
          MicrosoftLoginApi("shop-owner", token, setIsMicrosoftSignIn, setErrorMessage)

        } else {
          console.log("Microsoft login failed");
          setMicrosoftEssen(essential);
        }
      } catch (err) {
        console.error("Error occurred during Microsoft login:", err);
        setMicrosoftEssen(essential);
      }
    }
    else {
      toast.error('You must accept the Terms & Services to continue.')
    }
  };


  const MicrosoftLoginButton = () => {
    return (
      <div className="loginMicrosoftButton" onClick={handleMicrosoftLoginClick} style={{ cursor: "pointer" }}  >
        <span className="loginButtonIcon">
          <BsMicrosoft className="text-dark" style={{ fontSize: "14px" }} /></span>
        <span className="loginButtonText">Continue with Microsoft</span>
      </div>
    )
  }


  const toggleTermsAndCondition = () => {
    setTermsAndCondition(prevState => !prevState);
    // console.log(TermsAndCondition)
  };

  const handleLoginClick = (loginFunction) => {
    if (!TermsAndCondition) {
      toast.error('You must accept the Terms & Services to continue.');
    } else {
      loginFunction();
    }
  };

  const handleSuccess = (response) => {
    console.log('Login Success:', response);
    
  };

  const handleFailure = (error) => {
    if (error && error.error) {
      console.error('Error Message:', error.error.message);
    }
    if (error && error.error_description) {
      console.error('Error Description:', error.error_description);
    }
  };

  const appleResponse = response => {
    setAppleToken(response?.authorization?.id_token)
    setAppleCode(response?.authorization?.code)
    const token=response?.authorization?.id_token
    const code =response?.authorization?.code
    AppleLoginApi("shop-owner", token, setIsAppleSignIn, setErrorMessage, code)

  };


  return (
    <div className="login-background " >
      {/* {isLoading && <div className="loader-container">
                    <div className="spinner"></div>
            </div> } */}
      <ToastContainer />
      <div className="text-center pt-5 " >
        <img src={logo} height="4%" width="5%" /><span className=" b4uLogo" >B4YouFly</span>
      </div>
      <div className="container" >
        <h3 className="fw-bold text-center " style={{ color: "#03045E" }}>Welcome Back!</h3>
        <h5 className="mb-3 text-center ">Login To Continue</h5>
        <div className="dashline " />

        <GoogleLogin
          clientId={clientId}
          prompt="select_account"
          onSuccess={onSuccess}
          onFailure={onFailure}
          render={renderProps => (
            <div className="loginGoogleButton" onClick={() => handleLoginClick(renderProps.onClick)}  >
              <span className="loginButtonIcon">
                <GrGooglePlus className="text-danger " /></span>
              <span className="loginButtonText">Continue with Google</span>
            </div>
          )}
        >
        </GoogleLogin>

        <FacebookLogin
          appId={facebookAppId}
          autoLoad={false}
          // fields="name,email"
          fields="email"
          scope="public_profile,email"
          onClick={clickedComponent}
          callback={responseFacebook}
          render={renderProps => (
            <div className="loginFacebookButton" style={{ cursor: "pointer" }} onClick={renderProps.onClick}>
              <span className=" loginButtonIcon">
                <i className="bi bi-facebook text-primary"></i></span>
              <span className="loginButtonText">Continue with Facebook</span>
            </div>
          )
          }
        />

        <MicrosoftLoginButton />
        {isSdkLoaded &&
          <AppleLogin
            clientId={appleClientId}
            redirectURI={RedirectUri}
            responseType="code id_token"
            responseMode="form_post"
            usePopup={true}
            scope="name email"
            callback={appleResponse}
            render={renderProps => (
              <div className="appleLoginButton" style={{ cursor: "pointer" }} onClick={() => handleLoginClick(renderProps.onClick)}>
                <span className="loginButtonIcon">
                  <i className="bi bi-apple text-dark"></i>
                </span>
                <span className="loginButtonText">Continue with Apple</span>
              </div>
            )}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
          />
        }
        <div className="form-check mt-2">
          <input className="form-check-input" type="checkbox" onChange={toggleTermsAndCondition} id="flexCheckDefault" />
          <label className="form-check-label mx-3" for="flexCheckDefault">
            <a href="https://b4youfly.com/terms-of-service.html" target="_blank" className="fw-bold">Terms & Services</a>
          </label>
        </div>

      </div>
    </div>
  )
}

export default Login;