/* eslint-disable */
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

var refreshCount =0

export const NavigateToLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/", {replace : true});
  }, [])
};

const baseAPIURL = process.env.React_App_BaseUrl;

export const api = axios.create({
  baseURL: baseAPIURL,
});

api.interceptors.request.use(
  (config) => {
    if (config.url !== "auth/google-login/" && config.url !== "auth/facebook-login/" && config.url !== "auth/microsoft-login/") {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error?.response &&
      error.response.status === 401 &&
      !originalRequest._retryRefresh && refreshCount <= 5
    ) {
      try {
        refreshCount += 1
        const refreshToken = localStorage.getItem("refresh_token");
        const response = await api.post("auth/token/refresh/", {
          refresh: refreshToken,
        });
        const newAccessToken = response?.data?.access;
        localStorage.setItem("access_token", newAccessToken);
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        originalRequest._retryRefresh = false;
        return api(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        console.log("Retry refresh token failed. Navigating to login screen.");
        return Promise.reject(refreshError);
      }
    } else if (
      error?.response &&
      error.response.status &&
      ( originalRequest._retryRefresh || refreshCount > 5 )
    ) {
      console.log(
        "Token refresh failed multiple times. Navigating to login screen."
      );
      <NavigateToLogin/>
    } else if (error?.response && !originalRequest._retryRefresh) {
      const status = error?.response?.status;
      if (status == 400 && originalRequest.url == "auth/token/refresh/") {
        return Promise.reject(error);
      } 
    }

    return Promise.reject(error);
  }
);

