
//  import 'react-big-calendar/lib/addons/dragAndDrop/styles'
import React , {useState,useEffect} from 'react';
import { Calendar } from 'react-big-calendar'
import 'moment/locale/en-gb'; 
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TimeRange from '../../components/TimePicker';
import { momentLocalizer } from "react-big-calendar";
import dayjs from 'dayjs'
import moment from 'moment';
import { IoIosArrowDown } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import "./SchedulePage.css"
import { useNavigate } from 'react-router-dom';
import { AcceptAppointment, AcceptWorkOrder, CancelAppointment, CreateShopBusyAppointment, DeleteParticularShopBusy, FetchUserInfo, RetrieveShopBusyAppointment, UpdateShopBusyAppointment, cancelWorkOrder, getAppointmentListDetails, getWorkorderListDetails, rescheduleAppointmentApi, rescheduleWorkorderApi } from './appointmentApi/appointmentApi';
import logo from "../../assets/logo.png";
import { Dropdown } from 'react-bootstrap'; 
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";



let st;
let ed;

 const MyAppointments = (props)=> {

  

  const [myEventsList, setMyEventsList] = useState();
  const [show,setShow]=useState(false)
  const [startTime,setStartTime]=useState(null)
  const [endTime,setEndTime]=useState(null)
  const [title,setTitle]=useState("")
  const [allDay,setAllDay]=useState(true)
  const [isCreate,setIsCreate]=useState(true)
  const [userInfo,setUserInfo]=useState()
  const [shopUid,setShopUid]=useState()
  const [dataListing,setDataListing]=useState()
  const [isOpen,setIsOpen] = useState(false)
  const [userName,setUserName]=useState()
  const [email,setEmail]=useState("")
  const [errorMessage,setErrorMessage]=useState()
  const [shopBusySettingUid,setshopBusySettingUid]=useState()
  const [shopBusyTimingSlotUid,setShopBusyTimingSlotUid]=useState()
  const [selectedEventTitle,setSelectedEventTitle]=useState("")
  const [rescheduleAppointment,setRescheduleAppointment]=useState(false)
  const [rescheduleWorkorder,setRescheduleWorkorder]=useState(false)
  const [AppointmentUid,setAppointmentUid]=useState("")
  const [WorkorderUid,setWorkorderUid]=useState("")
  const [WorkingHourUid,setWorkingHourUid]=useState("")
  const [AppointmentDetail,setAppointmentDetail]=useState("")
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [status,setStatus]=useState()
  const [pendingOn,setPendingOn]=useState()
  const [isTitle,setIsTitle]=useState(true)

  let accessToken = localStorage.getItem("accessToken")

  const navigate =useNavigate()

  useEffect(() => {
    FetchUserInfo(accessToken,setShopUid) 
    setUserName(localStorage.getItem("userName"))
    setEmail(localStorage.getItem("email"))
  }, [])

  useEffect(() => {
    if(shopUid){
      RetrieveShopBusyAppointment(shopUid,setDataListing)
    }
  }, [shopUid])

  useEffect(() => {
    if(errorMessage){
      toast.error(errorMessage)
    }
    setErrorMessage(null)
  }, [errorMessage])

  const modifiedIncreaseDateByOne = (dateString) => {
    const [datePart, timePart] = dateString.split(' ');
    const dateObj = new Date(datePart);
    dateObj.setDate(dateObj.getDate() + 1);
    const newDatePart = dateObj.toISOString().slice(0, 10);
    return `${newDatePart} ${timePart}`;
};

  
  useEffect(()=>{
  if(dataListing){
    const transformedEvents = dataListing.reduce((acc, dayData) => {
      const { date,is_day_busy,title,day_busy_start_date,day_busy_end_date, working_hours, shop_busy_timing_slots, appointments, work_orders,day_busy_end_time,day_busy_start_time, shop_busy_setting_uid} = dayData;
      
      
      if (day_busy_start_date && day_busy_end_date && is_day_busy){
        acc.push({
          title: title,
          name:"Shop Busy",
          shop_busy_setting_uid:shop_busy_setting_uid,
          start: new Date(`${day_busy_start_date}`),
          end: new Date(day_busy_end_date),
          color: '#1199fa' 
        })
      }
     
      working_hours?.forEach(workingHour => {
        acc.push({
          title: workingHour.title,
          start: new Date(`${workingHour?.date}T${workingHour.start_time}`),
          end: new Date(`${workingHour?.date}T${workingHour.end_time}`),
          color: workingHour.title==="Working Hours"? '#656ead' : workingHour.title==="After Working Hour" ? "#7622ab" : "#1199fa" // Color for working hours
        });
      });

      
      shop_busy_timing_slots?.forEach(busySlot => {
       

          const shopBusyDate = {
            uid:busySlot.uid,
            shop_busy_setting_uid:shop_busy_setting_uid,
            name:"Shop Busy",
            title:busySlot.title,
            color: '#1199fa' 
          }

          if(!is_day_busy && (day_busy_start_date && day_busy_end_date)){
            const startDateTime=new Date(`${day_busy_start_date}T${busySlot.busy_from}`)
            const endDateTime =new Date(`${day_busy_end_date}T${busySlot.busy_to}`)
            shopBusyDate["start"]= startDateTime
            shopBusyDate["end"]= endDateTime
          }else{
            shopBusyDate.start=new Date(`${date}T${busySlot.busy_from}`)
            shopBusyDate.end= new Date(`${date}T${busySlot.busy_to}`)
          }

        acc.push(shopBusyDate);
      });

      // Add appointments
      appointments?.forEach(appointment => {
        acc.push({
          name:"Appointment",
          title: appointment.title,
          working_hour_uid:appointment.working_hour_uid,
          uid:appointment.uid,
          start: new Date(`${appointment?.date}T${appointment.start_time}`),
          end: new Date(`${appointment?.date}T${appointment.end_time}`),
          color: 'green' 
        });
      });

      // Add work orders
      work_orders?.forEach(workOrder => {
        acc.push({
          name:"Workorder",
          title: workOrder.title,
          start: new Date(`${workOrder.date}T${workOrder.start_time}`),
          end: new Date(`${workOrder.date}T${workOrder.end_time}`),
          uid:workOrder.uid,
          color: 'orange' 
        });
      });

      return acc;
    }, []);
    setMyEventsList(transformedEvents)
    }
  },[dataListing])

 const localizer = momentLocalizer(moment);   
 useEffect(() => {
    moment.updateLocale('en-gb', {
        week: {
        dow: 1, 
        },
    });

 }, [])

 
  const handleEventSetterModal=(props)=>{
    // if (title) {
      const newEvent = {
        start:startTime?startTime:st,
        end:endTime?endTime:ed,
        title,
        shop_busy_setting_uid:shopBusySettingUid,
        uid:shopBusyTimingSlotUid
      };
      if(isCreate){
        if(title==""){
          setIsTitle(false)
          toast("title is empty", {
            position: "top-right",
            type:"error",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });

            return;
        }else{

          CreateShopBusyAppointment(shopUid,newEvent.start,newEvent.end,setErrorMessage,setDataListing,title,startDate,endDate)
          setIsTitle(true)
        }
      }
      else{

        const props={
          shopUid:shopUid,
          startTime:newEvent.start,
          endTime:newEvent.end,
          shop_busy_setting_uid:newEvent.shop_busy_setting_uid,
          uid:newEvent.uid,
          setDataListing
        }
        UpdateShopBusyAppointment(props,title,startDate,endDate)
        setIsCreate(true)
      }
    // }
  
    setShow(false)
    setAllDay(false)
  
};

const handleModalClose=()=>{
  if(rescheduleWorkorder){
    setRescheduleWorkorder(false)
  }
  else if(rescheduleAppointment){
    setRescheduleAppointment(false)
  }
  setShow(false)
  setIsCreate(true)
  setSelectedEventTitle("")
}
 
const handleDropdownToggle = () => {
  setIsOpen(!isOpen);
};

  const handleSelectSlot = (prop) => {
    const { start, end } = prop
    const startTime = start.getHours() === 0 && start.getMinutes() === 0
      ? new Date(start.setHours(10, 0, 0))
      : start;

      const endTime =  end.getHours() === 0 && end.getMinutes() === 0
      ? new Date(end.setHours(10, 0, 0))
      : end;

      if(prop.name=="Workorder" || prop.name=="Appointment"){
            st=start
            ed=end
            setShow(true)
            setStartDate(start)
            setEndDate(end)
          setStartTime(start)
          setEndTime(end)
        }
      else if(prop.title!=="After Working hours"&& prop.title!=="Working hours" && prop.title!=="Weekend Working hours" && (selectedEventTitle=="" || selectedEventTitle=="Shop Busy")){
        if(isCreate){
          setAllDay(true)
          st=start
          ed=end
          setShow(true)
          setStartDate(startTime)
            setEndDate(endTime)
          setStartTime(startTime)
          setEndTime(endTime)
        }else{
          st=start
          ed=end
          setShow(true)
          setStartDate(start)
            setEndDate(end)
          setStartTime(start)
          setEndTime(end)
        }
      }

}



const updateSelectedEvent=(event)=>{
  // console.log(event)
   setIsCreate(false)
   setshopBusySettingUid(event.shop_busy_setting_uid)
   setShopBusyTimingSlotUid(event.uid)
   setSelectedEventTitle(event?.name)
   const props={
    start:event.start,
    end:event.end,
    title:event.title,
    uid:event.uid,
    shop_busy_setting_uid:event.shop_busy_setting_uid
   }
   if(event.name=="Workorder"){
     getWorkorderListDetails(event.uid,setAppointmentDetail,setStartDate,setEndTime,setStartTime,setStatus,setPendingOn)
     setWorkorderUid(event.uid)
    }
    else if(event.name=="Appointment"){
      getAppointmentListDetails(event.uid,setAppointmentDetail,setStartDate,setEndTime,setStartTime,setStatus,setPendingOn)
      setAppointmentUid(event.uid)
      setWorkingHourUid(event.working_hour_uid)
   }
  

   if(event?.name){
    if(event?.name=="Appointment"){
      // props.working_hour_uid
    }
      props.name=event?.name
   }
   if(event?.title){
    if(event.title!=="After Working hours" && event.title!=="Working hours" && event.title!=="Weekend Working hours"){
      setTitle(event?.title)
    handleSelectSlot(props)
   }
  }
}
const handleReschedule=function (){
  if(selectedEventTitle=="Workorder" ){
    setRescheduleWorkorder(true)
  }
  else if(selectedEventTitle=="Appointment"){
    setRescheduleAppointment(true)
  }
}

const handleRescheduleAppointmentorWorkorder=function () {
  const st=formatDate(startDate)
  if(selectedEventTitle=="Workorder"){
    rescheduleWorkorderApi(WorkorderUid,st,startTime,endTime,setPendingOn)
    setShow(false)
    setRescheduleWorkorder(false)
  }
  else if(selectedEventTitle=="Appointment"){
    rescheduleAppointmentApi(AppointmentUid,WorkingHourUid,startDate,startTime,endTime,setStartTime,setEndTime,setPendingOn)
    setShow(false)
    setRescheduleAppointment(false)

    }
    setSelectedEventTitle("")
}

function acceptAppointmentorWorkOrder(){
  if(selectedEventTitle=="Workorder"){
    AcceptWorkOrder(WorkorderUid)
  }
  else if(selectedEventTitle=="Appointment"){
    AcceptAppointment(AppointmentUid)
  }
  setShow(false)
  setSelectedEventTitle("")
}

function rejectAppointmentorWorkOrder(){
  if(selectedEventTitle=="Workorder"){
    cancelWorkOrder(WorkorderUid )
  }
  else if(selectedEventTitle=="Appointment"){
    CancelAppointment(AppointmentUid)
  }
  setShow(false)
  setSelectedEventTitle("")
}

function handleChangeTitle(e){
  setTitle(e.target.value)
   
}


function logout(res) {
  
  let confirm = window.confirm("Are you sure you want to logout?");
  if(confirm){
    localStorage.clear()
    window.location.reload() 
  }
  
}


 function handleSlotDelete(){
  const deleteProps={
    shopUid,shopBusyTimingSlotUid,shopBusySettingUid,startDate:formatDate(startTime),setDataListing
  }
  DeleteParticularShopBusy(deleteProps)
  setShow(false)
  setIsCreate(true)

 }

const eventStyleGetter = (event, start, end, isSelected) => {
  let backgroundColor = event.color;

  return {
    style: {
      backgroundColor,
    },
  };
};

function formatDate(dateString){
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; 
  const day = date.getDate();

  return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`

}

  const modifiyEndTime=(val)=>{
  const adjustedEndDate = dayjs(val).subtract(1, 'day').toDate();
  return adjustedEndDate
}
  
  return (
  <div>
          
            <nav className="navbar " >
            <div >
              <img src={logo} className='imgReso' alt="logo"/>
              <span className="navbarLogoName" >
                B4YouFly
              </span>
            </div>
            {/* <div >
              <input className="searchbar" type="search" placeholder="search" />
              </div> */}
            {/* <div className="rounded-circle"><img src={logo} height="25px" alt="profile"/></div> */}
          <div className="d-flex">
         <ToastContainer />
          <h5 className="welcomeDiv" >Hello ,{userName?userName:email}</h5>
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={logout}> Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </div>
            </nav>

      <Calendar
        localizer={localizer}
        events={myEventsList}
        eventPropGetter={eventStyleGetter}
        startAccessor="start"
        endAccessor="end"
        onSelectSlot={(prop)=>handleSelectSlot(prop)}
        onSelectEvent={(e)=>updateSelectedEvent(e)}
        selectable
        style={{ height: "100vh" }}
      />
    <div className='modal'>
    { (selectedEventTitle=="Workorder" || selectedEventTitle=="Appointment" )? (<Modal show={show} onHide={handleModalClose} className="modalinnerDiv" >
          <Modal.Body >
              <div className='modalTitleDiv'>
                <h5 className='my-2' for="title">{selectedEventTitle}</h5>   
              </div>
              <div className='detailAppointment p-3'>

                    <div className='d-flex justify-content-between appointment-detail-container'>
                  <span className='fw-bold '>Plane</span> <span >{AppointmentDetail?.aircraft_name}</span>
                   </div>
                   <div className='d-flex justify-content-between appointment-detail-container'>
                    <div className='fw-bold '>{AppointmentDetail?.service_name } </div><div className=''> {AppointmentDetail?.mode_type}</div>
                    </div>
                    <div className='d-flex justify-content-between appointment-detail-container'>
                   <span className='fw-bold '>Order Id</span><span className=''>#{AppointmentDetail?.uid?.split("").slice(0,15).join("")}...</span>
                   </div>
                   
              <span className='badge rounded-pill bg-warning text-dark mb-2 '>
                    {AppointmentDetail?.status}
                   </span>
                  </div>
              <div>
                {!rescheduleAppointment && !rescheduleWorkorder ?
                <div>{((status=="accepted" )|| (status=="pending" )|| (status=="reschedule_requested" && pendingOn==="shop owner"))&& 
                <Button  className='mx-2 flex-end rescheduleButton' variant="secondary" onClick={handleReschedule}>
                   Reschedule
                  </Button>
                  }
                  </div>
                  :(
               <div > 
                     {status!="cancelled" &&  
                     <div>
                      <div className='m-4'>
                      <div >
                       <div><span className='mt-4 fw-bold'>Start :</span></div>
                          <TimeRange Time={startTime}
                                     setTime={setStartTime}/>
                      </div>
                      <div className=''>
                       <div> <span for="end" className='mt-4 fw-bold'>End :</span></div>
                        <TimeRange Time={modifiyEndTime(endTime)}
                                     setTime={setEndTime}/>
                      </div>
                      <div className=''>
                        <div><span className='mt-4 fw-bold'>Date :</span></div>
                        <DatePicker className='datepicker ' selected={startDate} onChange={(date) => setStartDate(date)} />
                      </div>
                      </div>
                      <div className='m-2 d-flex'>
                        <Button  className='mx-2 flex-end ' variant="secondary" onClick={handleRescheduleAppointmentorWorkorder}>
                             Reschedule
                        </Button>
                      </div>
                    </div>}
                  </div>)}
              </div>
          </Modal.Body>
          <Modal.Footer className="d-flex flex-direction-column">
             {((status=="reschedule_requested" && pendingOn=="shop owner") || (status=="pending" && pendingOn=="shop owner")) && (<Button  className='appButton' style={{backgroundColor:"#03045E"}} onClick={acceptAppointmentorWorkOrder}>
             Accept {selectedEventTitle}
            </Button>)}
           { (status=="accepted" || (status=="reschedule_requested" && pendingOn=="shop owner") || (status=="pending" && pendingOn=="shop owner") ) && <Button className='appButton' variant="danger" style={{color:"light"}} onClick={rejectAppointmentorWorkOrder}>
              Reject {selectedEventTitle}
            </Button>}
            <Button variant="light" style={{border:"1px solid red",color:"red"}} onClick={handleModalClose}>
              Cancel
            </Button>
          </Modal.Footer>
      </Modal>)
      :
      ((selectedEventTitle=="Shop Busy" || selectedEventTitle=="") && <Modal show={show} onHide={handleModalClose} className="modalinnerDiv" >
        <div className=''>
          <Modal.Body >
              <div className='modaldiv '>
                 <h5 className='mt-3 text-left'>Title:</h5><input className={`py-2 px-3 ${!isTitle?"border-2 border-danger":""}`} value={title} onChange={handleChangeTitle}/>
              </div>
              <div className=''>
                 <div className='modaldiv'>
                    <h5 className='mt-3 '>Start Date :</h5>
                   <div> <DatePicker className='datepicker' selected={startDate} onChange={(date) => setStartDate(date)} /></div>
                  </div>
                  <div className='modaldiv'>
                    <h5 className='mt-3 '>End Date :</h5>
                    <div><DatePicker className='datepicker' selected={endDate} onChange={(date) => setEndDate(date)} /></div>
                  </div>
                  </div>
             {allDay  ?(<div className='d-flex justify-content-around'>
              <span className='fw-bold'>{formatDate(st)} : { formatDate(ed)} </span>
              <button onClick={()=>setAllDay(false)} className="text-light bg-info" style={{borderRadius:"10px"}}>Set Time</button>
              </div>): <div>
              <div className='modaldiv ml-4'>
                <h5 className='mt-3'>Start Time:</h5>
                <div>
                  <TimeRange Time={startTime}
                             setTime={setStartTime}/>
              </div>
              </div>
              <div className='modaldiv '>
                <h5 for="end" className='mt-3'>End Time:</h5>
                <TimeRange Time={modifiyEndTime(endTime)}
                             setTime={setEndTime}/>
              </div>
              </div>}
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <div className='d-flex'>
            <Button variant="#03045E" style={{backgroundColor:"#03045E",color:"white"}} onClick={handleEventSetterModal}>
             {isCreate ? "Create" : "Update"}
            </Button>
           {!isCreate && <Button className='mx-2' variant="danger" style={{border:"1px solid red",color:"light"}} onClick={handleSlotDelete}>
              Delete
            </Button>}
            </div>
            <Button variant="light" style={{border:"1px solid red",color:"red"}} onClick={handleModalClose}>
              Cancel
            </Button>
          </Modal.Footer>
          </div>
      </Modal>)
     }
      </div>
  </div>)
}

export default MyAppointments;