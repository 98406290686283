import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api } from "../utils/axios-utils";
const token = localStorage.getItem("accessToken")

    export function GoogleLoginApi(owner,token,setIsGoogleSignIn,setErrorMessage){
        api.post(`auth/${owner}/google-login/`,
            {  "token":token ,
                "type":"WEB"   
            },
            {headers :{
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*'}
            })
        .then((res)=>{
                console.log("google api backend",res)
            localStorage.setItem("userName",res?.data?.user?.first_name)
                localStorage.setItem("refreshToken",res?.data?.refresh_token)
                localStorage.setItem("accessToken",res?.data?.access_token)
            setIsGoogleSignIn(true)
        }) 
        .catch((err)=>{
            // console.log(err?.response?.data?.detail)
            const error=(err?.response?.data?.detail )? (err?.response?.data?.detail) :( err?.response?.data?.message)

            setErrorMessage(error)
            // toast(error, {
            //     position: "top-right",
            //     autoClose: 4000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     theme: "dark",
            //     });
            // console.log(err)
        })
    }

    export function GoogleLogoutApi(refreshToken){

        api.post(`auth/logout/`
            ,{  refresh:refreshToken })
        .then((res)=>{
                console.log(res)
        }) 
        .catch((err)=>{
            console.log(err)
        })
    }

    export function FacebookLoginApi(owner,token,setIsFacebookSignIn,setErrorMessage){

        api.post(`auth/${owner}/facebook-login/`
            ,{  access_token:token ,
                "type":"WEB"},
            {headers :{
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*'}
         })
        .then((res)=>{
                //   console.log(res)
                localStorage.setItem("userName",res?.data?.user?.first_name)
                localStorage.setItem("refreshToken",res?.data?.refresh_token)
                localStorage.setItem("accessToken",res?.data?.access_token)
                setIsFacebookSignIn(true)

        }) 
        .catch((err)=>{
            const error=(err?.response?.data?.detail )? (err?.response?.data?.detail) :( err?.response?.data?.message)

            setErrorMessage(error)
            // toast(error, {
            //     position: "top-right",
            //     autoClose: 4000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     theme: "dark",
            //     });
        })
    }

    export function MicrosoftLoginApi(owner,token,setIsMicrosoftSignIn,setErrorMessage){
        api.post(`auth/${owner}/microsoft-login/`,
            {  access_token:token ,
                "type":"WEB"
            },
            {headers :{
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*'}
            })
        .then((res)=>{
                 console.log("microsoft api backend",res)
                 setIsMicrosoftSignIn(true)
                localStorage.setItem("userName",res?.data?.user?.first_name)
                localStorage.setItem("refreshToken",res?.data?.refresh_token)
                localStorage.setItem("accessToken",res?.data?.access_token)
        }) 
        .catch((err)=>{
            const error=(err?.response?.data?.detail )? (err?.response?.data?.detail) :( err?.response?.data?.message)

            setErrorMessage(error)
            // toast(error, {
            //     position: "top-right",
            //     autoClose: 4000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     theme: "dark",
            //     });
            // console.log(err)
        })
    }

    export function AppleLoginApi(owner,token,setIsAppleSignIn,setErrorMessage,appleCode){
        api.post(`auth/${owner}/apple-login-web/`,
            { 
                "code":appleCode,
                "id_token":token
            },
            {headers :{
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*'}
            })
        .then((res)=>{
                 console.log("apple api backend",res)
                 setIsAppleSignIn(true)
                localStorage.setItem("email",res?.data?.user?.email)
                localStorage.setItem("refreshToken",res?.data?.refresh_token)
                localStorage.setItem("accessToken",res?.data?.access_token)
        }) 
        .catch((err)=>{
            const error=(err?.response?.data?.detail )? (err?.response?.data?.detail) :( err?.response?.data?.message)
            setErrorMessage(error)
            // toast(error, {
            //     position: "top-right",
            //     autoClose: 4000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     theme: "dark",
            //     });
            // console.log(err)
        })
    }
